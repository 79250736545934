import {createApp} from 'vue'
import {
    ActionSheet,
    ActionBar,
    Step,
    Steps,
    DatePicker,
    Search,
    ActionBarIcon,
    ActionBarButton,
    Divider,
    Popup,
    Overlay,
    Loading,
    Dialog,
    ContactCard,
    Form,
    AddressEdit,
    AddressList,
    Field,
    CellGroup,
    Cell,
    SwipeCell,
    Icon,
    Stepper,
    Card,
    Checkbox,
    CheckboxGroup,
    Button,
    Swipe,
    SwipeItem,
    PullRefresh,
    List,
    Tab,
    Tabs,
    Tag,
    SubmitBar,
    Toast,
    Skeleton,
    Uploader,
    RadioGroup,
    Radio,
} from 'vant'
import App from './App.vue'
import store from './store'
import router from './router'
import 'lib-flexible/flexible'
import 'vant/lib/index.css'; // 全局引入样式
import './assets/font/iconfont.css'
import 'vant/es/toast/style';
const app = createApp(App) // 创建实例

// 全局过滤器
app.config.globalProperties.$filters = {
    prefix(url) {
        if (url && url.startsWith('http')) {
            return url
        } else {
            url = `http://backend-api-01.newbee.ltd${url}`
            return url
        }
    }
}

app.use(ActionBarButton)
    .use(ActionBarIcon)
    .use(ActionBar)
    .use(ActionSheet)
    .use(Search)
    .use(DatePicker)
    .use(Divider)
    .use(Step)
    .use(Steps)
    .use(Popup)
    .use(Overlay)
    .use(Tag)
    .use(Loading)
    .use(Dialog)
    .use(Toast)
    .use(ContactCard)
    .use(Form)
    .use(AddressEdit)
    .use(AddressList)
    .use(Field)
    .use(CellGroup)
    .use(Cell)
    .use(SwipeCell)
    .use(Icon)
    .use(Stepper)
    .use(Card)
    .use(Button)
    .use(Swipe)
    .use(SwipeItem)
    .use(PullRefresh)
    .use(List)
    .use(Tab)
    .use(Tabs)
    .use(SubmitBar)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Skeleton)
    .use(Uploader)
    .use(RadioGroup)
    .use(Radio)

app.use(router)
app.use(store)

app.mount('#app')
