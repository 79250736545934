import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/overflow/details',
      name: 'cart',
      component: () => import('@/views/overflow/Details.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/good/details',
      name: 'goodDetails',
      component: () => import('@/views/goods/details/index.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/good/order',
      name: 'goodOrder',
      component: () => import('@/views/goods/details/order.vue'),
      meta: {
        index: 1
      },
      props: true
    },
    {
      path: '/good/site',
      name: 'goodSite',
      component: () => import('@/views/goods/details/site.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/good/addSite',
      name: 'goodAddSite',
      component: () => import('@/views/goods/details/addSite.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/good/pay',
      name: 'goodPay',
      component: () => import('@/views/goods/details/pay.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('@/views/Cart.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/user/User.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/trace',
      name: 'trace',
      component: () => import('@/views/user/details/Trace.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/setUp',
      name: 'setUp',
      component: () => import('@/views/user/details/SetUp.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('@/views/user/details/Feedback.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/orderList',
      name: 'orderlist',
      component: () => import('@/views/user/details/OrderList.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/orderDetails',
      name: 'orderdetails',
      component: () => import('@/views/user/details/OrderDetails.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/followList',
      name: 'followlist',
      component: () => import('@/views/user/details/FollowList.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/idea',
      name: 'idea',
      component: () => import('@/views/user/details/Idea.vue'),
      meta: {
        index: 1
      }
    }
  ]
})

export default router
